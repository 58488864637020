import { fetchCategoriesFailure, fetchCategoriesRequest, fetchCategoriesSuccess } from 'src/reducer/categoryReducer';

async function fetchCategory(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual category URL

  try {
    dispatch(fetchCategoriesRequest());

    const response = await fetch(url + '/api/categories', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch category');
    }

    const categories = await response.json();
    dispatch(fetchCategoriesSuccess(categories));
    return categories;
  } catch (error) {
    console.error(error);
    dispatch(fetchCategoriesFailure(error));
    throw error;
  }
}

export default fetchCategory;
