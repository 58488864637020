import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import updatePromo from 'src/functions/promo/updatePromo';
import { ImBin, ImPencil } from 'react-icons/im';
import { motion } from 'framer-motion';
import deletePromo from 'src/functions/promo/deletePromo';

function SinglePromo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const { token } = useSelector((state) => state.user.users);
  const { promo } = useSelector((state) => state.promo);
  const { id } = useParams();
  const promocode = promo.find((promo) => promo.code === id);
  if (!promocode) {
    navigate('/dashboard/promo');
    return;
  }
  const [userInput, setUserInput] = useState({
    code: promocode?.code || '',
    discount: promocode?.discount || '',
    expiresAt: promocode?.expiresAt || '',
    title: promocode?.title || '',
    maxAmount: promocode?.maxAmount || '',
    displayOnHome: promocode?.displayOnHome || true,
    isActive: promocode?.isActive || true,
    id: promocode?._id || '',
  });

  function resetPromo(e) {
    e.preventDefault();
    setUserInput({
      code: promocode?.code || '',
      discount: promocode?.discount || '',
      expiresAt: promocode?.expiresAt || '',
      title: promocode?.title || '',
      maxAmount: promocode?.maxAmount || '',
      displayOnHome: promocode?.displayOnHome || true,
      isActive: promocode?.isActive || true,
      id: promocode?._id || '',
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!token) {
      alert('Please login to create promo');
      return;
    }
    if (!validateForm()) {
      alert('Please fill all the fields');
      return;
    }
    if (!validaeDate(userInput.expiresAt)) {
      alert('Please enter a valid expiry date');
      return;
    }
    const update = await updatePromo(userInput, token, dispatch);
    if (update) {
      alert('Promo Updated successfully');
      navigate('/dashboard/promo');
    } else {
      alert('Failed to create promo');
    }
  }

  async function deletePromox() {
    //  make a dialogbox to confirm the delete
    if (!token) {
      alert('Please login to delete promo');
      return;
    }

    const response = window.confirm('Are you sure you want to delete this promo?');
    if (!response) {
      return;
    }

    const deleteprom = await deletePromo(userInput, token, dispatch);
    if (deleteprom) {
      alert('Promo deleted successfully');
      navigate('/dashboard/promo');
    } else {
      alert('Failed to delete promo');
    }
  }

  function validaeDate(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    return currentDate < inputDate;
  }
  function validateForm() {
    // return where all the fields are filled
    if (userInput.code === '') {
      console.log('error at code');
      return false;
    }
    if (userInput.discount === '') {
      console.log('error at discount');
      return false;
    }
    if (userInput.expiresAt === '') {
      console.log('error at expiresAt');
      return false;
    }
    if (userInput.title === '') {
      console.log('error at title');
      return false;
    }
    if (userInput.maxAmount === '') {
      console.log('error at maxAmount');
      return false;
    }
    return true;
  }

  return (
    <div>
      <nav className="text-gray-500 mb-4">
        <ol className="list-none flex items-center space-x-2">
          <li>
            <Link to="/dashboard/promo" className="hover:text-gray-700">
              Promos
            </Link>
          </li>
          <li>/</li>

          <li className="text-gray-700">{promocode.code}</li>
        </ol>
      </nav>

      {edit && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className=" p-4 bg-gray-200 duration-300 transition-all"
        >
          <div className="flex bg-white rounded-md p-4 w-full">
            <form className="flex flex-col gap-4 w-full">
              <div className="flex flex-col gap-2">
                <label htmlFor="code">Promo Code</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      code: e.target.value,
                    }));
                  }}
                  name="code"
                  id="code"
                  defaultValue={promocode.code}
                  placeholder="Enter promo code"
                  className="border p-2 rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="discount">Discount Percentage</label>
                <input
                  type="text"
                  name="discount"
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      discount: e.target.value,
                    }));
                  }}
                  id="discount"
                  defaultValue={promocode.discount}
                  placeholder="Enter discount (in %)"
                  className="border p-2 rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="expiry">Expiry Date</label>
                <input
                  type="date"
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      expiresAt: e.target.value,
                    }));
                  }}
                  name="expiry"
                  id="expiry"
                  defaultValue={new Date(promocode.expiresAt).toISOString().split('T')[0]}
                  placeholder="Enter expiry date"
                  className="border p-2 rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  defaultValue={promocode.title}
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }));
                  }}
                  placeholder="Enter Promo Title"
                  className="border p-2 rounded-md"
                />
              </div>
              <div className="flex flex-col gap-2">
                <label htmlFor="max">Maximum Amount</label>
                <input
                  type="text"
                  name="max"
                  id="max"
                  defaultValue={promocode.maxAmount}
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      maxAmount: e.target.value,
                    }));
                  }}
                  placeholder="Enter maximum amount"
                  className="border p-2 rounded-md"
                />
              </div>

              <div className="flex flex-col items-start   justify-start    gap-2">
                <label htmlFor="doh">Display on Home</label>
                <input
                  type="checkbox"
                  defaultChecked={promocode.displayOnHome}
                  name="doh"
                  id="doh"
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      displayOnHome: e.target.checked,
                    }));
                  }}
                  // placeholder="Enter maximum amount"
                  className="border p-2 rounded-md h-10 w-10 checked:text-green-500"
                />
              </div>

              <div className="flex flex-col items-start   justify-start    gap-2">
                <label htmlFor="active">Active?</label>
                <input
                  type="checkbox"
                  defaultChecked={promocode.isActive}
                  name="active"
                  onChange={(e) => {
                    setUserInput((prev) => ({
                      ...prev,
                      isActive: e.target.checked,
                    }));
                  }}
                  id="active"
                  // placeholder="Enter maximum amount"
                  className="border p-5 rounded-md h-10 w-10

                "
                />
              </div>
              <div className="flex gap-3 w-full justify-center items-center">
                <button onClick={(e) => resetPromo(e)} className="bg-red-500 w-1/2 text-white p-2 rounded-md">
                  Reset
                </button>

                <button onClick={handleSubmit} className="bg-blue-500 w-1/2 text-white p-2 rounded-md">
                  Update Promo
                </button>
              </div>
            </form>
          </div>
        </motion.div>
      )}

      <div className="flex flex-col gap-4 bg-gray-100 p-4 rounded-md">
        {!edit && (
          <motion.div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2">
              <h2>
                <span className="text-gray-500">Code:</span> {promocode.code}
              </h2>
            </div>
            <div className="flex flex-col gap-2">
              <h2>
                <span className="text-gray-500">Discount:</span> {promocode.discount}%
              </h2>
            </div>
            <div className="flex flex-col gap-2">
              <h2>
                <span className="text-gray-500">Expiry:</span> {new Date(promocode.expiresAt).toDateString()}
              </h2>
            </div>
            <div className="flex flex-col gap-2">
              <h2>
                <span className="text-gray-500">Title:</span> {promocode.title}
              </h2>
            </div>
            <div className="flex flex-col gap-2">
              <h2>
                <span className="text-gray-500">Max Amount:</span> {promocode.maxAmount}
              </h2>
            </div>
            <div className="flex flex-col items-start   justify-start    gap-2">
              <h2>
                <span className="text-gray-500">Display on Home:</span> {promocode.displayOnHome ? 'Yes' : 'No'}
              </h2>
            </div>
            <div className="flex flex-col items-start   justify-start    gap-2">
              <h2>
                <span className="text-gray-500">Active:</span> {promocode.isActive ? 'Yes' : 'No'}
              </h2>
            </div>
          </motion.div>
        )}
        <div className="flex gap-4">
          <button
            onClick={(e) => {
              setEdit(!edit);
            }}
            className="bg-blue-500 text-white p-2 rounded-md flex gap-3 items-center"
          >
            <ImPencil />
            {edit ? 'Cancel' : 'Edit'}
          </button>
          <button onClick={deletePromox} className="bg-red-500 text-white p-2 rounded-md flex gap-3 items-center">
            <ImBin />
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default SinglePromo;
