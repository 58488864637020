import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopOfferCard.propTypes = {
  product: PropTypes.object,
};


export default function ShopOfferCard({ product }) {
  const { title, image, price, categories, discount, isNew } = product;

  const navigate = useNavigate();


  function navTo() {
    navigate(`/dashboard/offers/${product.slug}`);
  }


  const priceWithDiscount = price * (1 - discount / 100);
  const finalPrice = priceWithDiscount.toFixed(2); // Round to 2 decimal places


  return (
    <Card className='hover:scale-110 ease-in-out transition-all' onClick={() => navTo()}>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {isNew && (
          <Label
            variant="filled"
            color={(isNew === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {(isNew) ? "New" : ""}
          </Label>
        )}
        <StyledProductImg alt={title} src={image} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {title}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">

          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {/* {discount && fCurrency(price)} */}
            </Typography>
            &nbsp;
            {fCurrency(finalPrice)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
