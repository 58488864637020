import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import OfferModel from '../components/Popups/OfferModel';
import OfferList from '../sections/@dashboard/products/OfferList';

export default function OfferPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState('all');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchType, setSearchType] = useState('all');
  const [length, setLength] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const queryId = searchParams.get('q');

    if (!!queryId) {
      fetch(`${url}/api/offers`)
        .then((response) => response.json())
        .then((data) => {
          // filter
          const searchData = data.filter((item) => {
            return item.title
              .toLowerCase()
              .includes(
                queryId.toLowerCase() ||
                  item.description.toLowerCase().includes(queryId.toLowerCase()) ||
                  item.categories[0].title.toLowerCase().includes(queryId.toLowerCase())
              );
          });
          setProducts(searchData);
          setLength(searchData.length);
          setLoading(false);
        })

        .catch((error) => {
          console.log(error);
        });
    }
  }, [location.search]);

  function filterProducts() {
    if (searchType === 'all') {
      fetchProducts();
    } else {
      setLoading(true);
      fetch(`${url}/api/offers`)
        .then((response) => response.json())
        .then((data) => {
          // filter
          const filtered = data.filter((item) => {
            return item.categories[0].title === searchType;
          });
          setProducts(filtered);

          setLength(filtered.length);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    fetchProducts();
  }, []);

  const url = process.env.REACT_APP_URL;

  function fetchProducts() {
    fetch(`${url}/api/offers`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
        setLength(data.length);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function search() {
    const search = document.getElementById('search').value;

    setLoading(true);
    if (!!search) {
      navigate(`/dashboard/offers?q=${search}`);
    } else {
      fetchProducts();
      navigate(`/dashboard/offers`);
    }
  }

  if (loading) {
    return (
      <>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Added Products
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={'space-between'}
            width={'100%'}
            flexShrink={0}
            sx={{ my: 1 }}
          >
            <div className="flex">
              <select
                onChange={(e) => setSearchType(e.target.value)}
                className="px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">All</option>
                <option value="Mobile Cover">Mobile Cases</option>
                <option value="Pop Sockets">Pop Sockets</option>
                <option value="Airpod Cases">Airpod Cases</option>
                <option value="Laptop Sleeves">Laptop Sleeves</option>
                <option value="Gaming Mouse Pad">Gaming Mouse Pad</option>
              </select>
              <button
                onClick={filterProducts}
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Filter
              </button>
            </div>

            <OfferModel />
          </Stack>
        </Stack>

        <div className="flex gap-2 mb-5">
          <input
            id="search"
            type="text"
            placeholder="Search"
            className="px-4 w-full py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            onClick={search}
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Search
          </button>
        </div>
        <h2>Loading...</h2>
      </>
    );
  }

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Added Offers
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={'space-between'}
            width={'100%'}
            flexShrink={0}
            sx={{ my: 1 }}
          >
            <div className="flex">
              <select
                onChange={(e) => setSearchType(e.target.value)}
                className="px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">All</option>
                <option value="Silicon">Silicon</option>
                <option value="Premium">Premium</option>
              </select>
              <button
                onClick={filterProducts}
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Filter
              </button>
            </div>

            {/* Add offer */}
            <OfferModel />
          </Stack>
        </Stack>

        <div className="flex gap-2 mb-5">
          <input
            id="search"
            type="text"
            placeholder="Search"
            className="px-4 w-full py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <button
            onClick={search}
            type="button"
            className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Search
          </button>
        </div>

        {!products ? (
          <h2>No Offers Available</h2>
        ) : (
          <div className="flex flex-col">
            <OfferList type={searchType} page={page} searchData={searchInput} products={products} />
            {!loading && (
              <div className="flex gap-2 justify-between items-center p-5">
                <button
                  onClick={() => setPage(page - 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Prev
                </button>

                <h2>
                  Page {page} - Showing 10 out of {length} results
                </h2>

                <button
                  onClick={() => setPage(page + 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
}
