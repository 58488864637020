async function createPromo(data, token, dispatch) {
  try {
    const url = process.env.REACT_APP_URL; // Replace with your actual product URL

    // Upload the data to the endpoint
    const response = await fetch(url + '/api/promocodes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return true;
    } else {
      console.error('Failed to upload data:', response.status);
      return false;
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

export default createPromo;
