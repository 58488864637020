import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import LoginPage from './pages/LoginPage';
import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveUsers } from 'src/reducer/usersReducer';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function App() {
  const user = JSON.parse(localStorage.getItem('users'));
  const [auth, setAuth] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.token) {
      dispatch(saveUsers(user));
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          {auth ? <Router /> : <LoginPage />}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
