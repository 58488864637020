import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import createPromo from 'src/functions/promo/createPromo';

function CreatePromo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.users);
  const [userInput, setUserInput] = useState({
    code: '',
    discount: '',
    expiresAt: '',
    title: '',
    maxAmount: '',
    displayOnHome: true,
    isActive: true,
  });

  async function handleSubmit(e) {
    e.preventDefault();
    if (!token) {
      alert('Please login to create promo');
      return;
    }
    if (!validateForm()) {
      alert('Please fill all the fields');
      return;
    }
    if (!validaeDate(userInput.expiresAt)) {
      alert('Please enter a valid expiry date');
      return;
    }
    const upload = await createPromo(userInput, token, dispatch);
    if (upload) {
      alert('Promo created successfully');
      navigate('/dashboard/promo');
    } else {
      alert('Failed to create promo');
    }
  }

  function validaeDate(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    return currentDate < inputDate;
  }
  function validateForm() {
    return (
      userInput.code.length > 0 &&
      userInput.discount.length > 0 &&
      userInput.expiresAt.length > 0 &&
      userInput.title.length > 0 &&
      userInput.maxAmount.length > 0
    );
  }

  return (
    <div className="p-2 flex flex-col gap-3">
      {/* bradcrumb */}
      <nav className="text-gray-500 mb-4">
        <ol className="list-none flex items-center space-x-2">
          <li>
            <Link to="/dashboard/promo" className="hover:text-gray-700">
              Promos
            </Link>
          </li>
          <li>/</li>
          <li className="text-gray-700">Create</li>
        </ol>
      </nav>
      <div className="flex p-4 bg-gray-200">
        <div className="flex bg-white rounded-md p-4 w-full">
          <form className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-2">
              <label htmlFor="code">Promo Code</label>
              <input
                type="text"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    code: e.target.value,
                  }));
                }}
                name="code"
                id="code"
                placeholder="Enter promo code"
                className="border p-2 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="discount">Discount Percentage</label>
              <input
                type="text"
                name="discount"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    discount: e.target.value,
                  }));
                }}
                id="discount"
                placeholder="Enter discount (in %)"
                className="border p-2 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="expiry">Expiry Date</label>
              <input
                type="date"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    expiresAt: e.target.value,
                  }));
                }}
                name="expiry"
                id="expiry"
                placeholder="Enter expiry date"
                className="border p-2 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                id="title"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
                placeholder="Enter Promo Title"
                className="border p-2 rounded-md"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="max">Maximum Amount</label>
              <input
                type="text"
                name="max"
                id="max"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    maxAmount: e.target.value,
                  }));
                }}
                placeholder="Enter maximum amount"
                className="border p-2 rounded-md"
              />
            </div>

            <div className="flex flex-col items-start   justify-start    gap-2">
              <label htmlFor="doh">Display on Home</label>
              <input
                type="checkbox"
                defaultChecked={true}
                name="doh"
                id="doh"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    displayOnHome: e.target.checked,
                  }));
                }}
                // placeholder="Enter maximum amount"
                className="border p-2 rounded-md h-10 w-10 checked:text-green-500"
              />
            </div>

            <div className="flex flex-col items-start   justify-start    gap-2">
              <label htmlFor="active">Active?</label>
              <input
                type="checkbox"
                defaultChecked={true}
                name="active"
                onChange={(e) => {
                  setUserInput((prev) => ({
                    ...prev,
                    isActive: e.target.checked,
                  }));
                }}
                id="active"
                // placeholder="Enter maximum amount"
                className="border p-5 rounded-md h-10 w-10

                "
              />
            </div>
            <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded-md">
              Create Promo
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreatePromo;
