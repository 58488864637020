// components
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, Stack, Typography } from '@mui/material';
import { ModelReact } from '../components/Popups/ModelReact';
import { ProductList } from '../sections/@dashboard/products';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import fetchProduct from 'src/functions/products/fetchProduct';
import fetchCategory from 'src/functions/category/fetchCategory';

export default function ProductsPage() {
  const [products, setProducts] = useState([]);
  const [searchType, setSearchType] = useState('all');

  const [length, setLength] = useState(0);
  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { loading: loadingProduct, products: productsData } = useSelector((state) => state.product);
  const { categories: categoriesData } = useSelector((state) => state.category);
  const { users } = useSelector((state) => state.user);

  function filterProducts() {
    setPage(1);

    if (searchType === 'all') {
      if (!!searchInput) {
        const filteredData = productsData.filter((item) =>
          item.title.toLowerCase().includes(searchInput.toLowerCase())
        );
        setProducts(filteredData);
        setLength(filteredData.length);
      } else {
        setProducts(productsData);
        setLength(productsData.length);
      }
    } else {
      if (!!searchInput) {
        const filteredData = productsData.filter(
          (item) => item.title.toLowerCase().includes(searchInput.toLowerCase()) && item.categories[0] === searchType
        );
        setProducts(filteredData);
        setLength(filteredData.length);
      } else {
        const filteredData = productsData.filter((item) => item?.category?._id === searchType);
        setLength(filteredData.length);
        setProducts(filteredData);
      }
    }
  }

  const fetchprods = async () => {
    if (!users?.token) return navigate('/login');
    if (categoriesData?.length === 0) {
      let res = await fetchCategory(dispatch, users?.token);
    }

    if (productsData?.length === 0) {
      let res = await fetchProduct(dispatch, users?.token);
      if (res) {
        setProducts(res);
        setLength(res.length);
      }
    } else {
      setProducts(productsData);
      setLength(productsData.length);
    }
  };

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    fetchprods();
  }, []);

  const url = process.env.REACT_APP_URL;

  useEffect(() => {
    const delay = 300; // Delay time in milliseconds

    const debounceTimer = setTimeout(() => {
      if (!!searchInput) {
        navigate(`/dashboard/products?q=${searchInput}`);
        if (searchType !== 'all') {
          const filterProduct = productsData.filter(
            (item) => item.title.toLowerCase().includes(searchInput.toLowerCase()) && item.categories[0] === searchType
          );
          setProducts(filterProduct);
          setLength(filterProduct.length);
        } else {
          const filterProduct = productsData.filter((item) =>
            item.title.toLowerCase().includes(searchInput.toLowerCase())
          );
          setProducts(filterProduct);
          setLength(filterProduct.length);
        }
      } else {
        fetchprods();
        navigate(`/dashboard/products`);
      }
    }, delay);

    return () => clearTimeout(debounceTimer);
  }, [searchInput]);

  if (loadingProduct) return <h2>Loading...</h2>;

  return (
    <>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Added Products
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack
            direction="row"
            spacing={1}
            justifyContent={'space-between'}
            width={'100%'}
            flexShrink={0}
            sx={{ my: 1 }}
          >
            <div className="flex">
              <select
                onChange={(e) => setSearchType(e.target.value)}
                className="px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              >
                <option value="all">All</option>
                {categoriesData.map((category) => (
                  <option key={category?.id} value={category?._id}>
                    {category?.title}
                  </option>
                ))}
              </select>
              <button
                onClick={filterProducts}
                type="button"
                className="px-4 py-2 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Filter
              </button>
            </div>

            <ModelReact />
          </Stack>
        </Stack>

        <div className="flex gap-2 mb-5">
          <input
            id="search"
            type="text"
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            placeholder="Search"
            className="px-4 w-full py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>

        {!products ? (
          <h2>No Product Available</h2>
        ) : (
          <div className="flex flex-col">
            <ProductList type={searchType} page={page} searchData={searchInput} products={products} />
            {!loadingProduct && (
              <div className="flex gap-2 justify-between items-center p-5">
                <button
                  onClick={() => setPage(page - 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Prev
                </button>

                <h2>
                  Page {page} - Showing {products.length < 10 ? products.length : 10} out of {length} results
                </h2>

                <button
                  onClick={() => setPage(page + 1)}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Next
                </button>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
}
