// Define the action types
const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

// Define the action creators
const fetchOrdersRequest = () => {
  return {
    type: FETCH_ORDERS_REQUEST,
  };
};

const fetchOrdersSuccess = (orders) => {
  return {
    type: FETCH_ORDERS_SUCCESS,
    payload: orders,
  };
};

const fetchOrdersFailure = (error) => {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: error,
  };
};

// Define the initial state
const initialState = {
  loading: false,
  orders: [],
  error: null,
};

// Define the ordersReducer function
const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ordersReducer;
export { fetchOrdersRequest, fetchOrdersSuccess, fetchOrdersFailure };
