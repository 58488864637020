/* eslint-disable */
import { Card, Input, Checkbox, Textarea, Button, Typography, Option } from '@material-tailwind/react';
import { Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchProduct from 'src/functions/products/fetchProduct';
import { useSelector } from 'react-redux';

export function ProductAdd() {
  const url = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [categories, setCategories] = useState([]);

  function getCategory() {
    fetch(`${url}/api/categories`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      });
  }

  useEffect(() => {
    getCategory();
  }, []);

  const [image, setImage] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [category, setCategory] = useState(null);

  function getImage(e) {
    setImage(e.target.files[0]);
    setImageLink(URL.createObjectURL(e.target.files[0]));
  }

  function upload() {
    const formData = new FormData();
    formData.append('image', image);

    fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const imageUrl = data.data.display_url;

        fetch(`${url}/api/products`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: name,
            description: description,
            price: price,
            category,
            isNew: true,
            discount: 0,
            tags: 'case, mobile',
            image: imageUrl,
          }),
        })
          .then((res) => {
            if (res.status === 400) {
              return toast.error('Please fill all fields');
            }
            return res.json();
          })
          .then((data) => {
            setImage(null);
            fetchProduct(dispatch, users?.token);
            setImageLink(null);
            addedNotify();
          });
      });
  }

  const addedNotify = () => {
    toast.success('Product added successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <Card color="transparent" className="overflow-auto h-96" shadow={false}>
      <form className="mt-8 mb-2  w-100 max-w-screen-lg">
        <div className="mb-4 flex flex-col gap-6">
          <Input onChange={(e) => setName(e.target.value)} size="lg" label="Name" />
          <Textarea onChange={(e) => setDescription(e.target.value)} size="lg" label="Description" />
          <Input onChange={(e) => setPrice(e.target.value)} type="number" size="lg" label="Price" />
          {/* Image */}
          <Input onChange={(e) => getImage(e)} type="file" size="lg" label="Image" />
          {!!imageLink && <img className="h-40 object-contain" src={imageLink} alt="" />}
          <label className="text-gray-700 text-sm font-bold mb-2" htmlFor="category">
            Category
          </label>

          <select
            size="lg"
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
            className="
                    bg-white
                    border
                    border-gray-400
                    hover:border-gray-500
                    px-4
                    py-2
                    pr-8
                    rounded
                    shadow
                    leading-tight
                    focus:outline-none
                    focus:shadow-outline
                    appearance-none
                    
                    "
          >
            <option value="" disabled selected>
              Select Category
            </option>
            {categories.map((category, index) => {
              return (
                <option key={index} value={category._id}>
                  {category.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="flex gap-5 justify-center items-start">
          <Button className="mt-6" onClick={upload} fullWidth>
            Save
          </Button>
        </div>
        <ToastContainer />
      </form>
    </Card>
  );
}
