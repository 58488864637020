import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import getTypes from 'src/functions/casetype/getTypes';

function Casetype() {
  const [caseTypes, setCaseTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  async function fetchTypes() {
    const data = await getTypes();
    if (data) {
      setCaseTypes(data);
      setLoading(false);
    } else {
      setError('An error occurred');
    }
  }

  useEffect(() => {
    fetchTypes();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }
  return (
    <div className="p-2">
      <nav className="text-gray-500 mb-4">
        <ol className="list-none flex items-center space-x-2">
          <li>
            <Link to="/dashboard/app" className="hover:text-gray-700">
              Home
            </Link>
          </li>
          <li>/</li>

          <li className="text-gray-700">Case Types</li>
        </ol>
      </nav>
      <h1>Case Types</h1>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3  pt-3">
        {caseTypes.map((type) => (
          <div key={type._id} className="border p-4 rounded-md shadow-md">
            <h2 className="text-lg font-bold">{type.name}</h2>
            <p>NPR. {type.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Casetype;
