import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { useDispatch } from 'react-redux';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { UserListToolbar, UserListHead } from '../sections/@dashboard/user';
import { useSelector } from 'react-redux';
// import fetchOrders from 'src/functions/orders/fetchOrders';
import fetchPromo from 'src/functions/promo/getPromo';
import { ImBoxAdd } from 'react-icons/im';
import { AiFillFileAdd } from 'react-icons/ai';
// sections
// mock
// import orders from '../_mock/user';

const relativeTime = require('dayjs/plugin/relativeTime');

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'active', label: 'Active', alignRight: false },
  // { id: 'location', label: 'Location', alignRight: false },
  { id: 'displayed', label: 'Displayed', alignRight: false },
  { id: 'code', label: 'Code', alignRight: false },
  { id: 'discount', label: 'Discount %', alignRight: false },
  { id: 'maxDiscount', label: 'Max Discount', alignRight: false },
  { id: 'Action', label: 'Actions', alignRight: false },
  { id: '' },
];

export default function Promocode() {
  const navigation = useNavigate();
  const { promo } = useSelector((state) => state.promo);
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(null);

  const [stat, setStat] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [SelectedItem, setSelectedItem] = useState({});

  const [showModel, setShowModel] = useState(false);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function getOrders() {
    setLoading(true);
    const ords = fetchPromo(dispatch, users?.token);
    if (ords) {
      setLoading(false);
    }
  }

  useEffect(() => {
    getOrders();
  }, []);

  const handleOpenPop = (value) => {
    navigation(`/dashboard/orders/${value._id}`);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = promo.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  function openPop(e) {
    setOpen(null);
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - promo.length) : 0;

  const isNotFound = promo.length === 0;

  return (
    <div className="w-full ">
      <Helmet>
        <title> Promocodes | Casemandu </title>
      </Helmet>

      <Container
      // width="100%"
      >
        <Stack direction="row" alignItems="center" gap={2} mb={5}>
          <Typography variant="h4" gutterBottom>
            Promocodes
          </Typography>
          <Iconify
            onClick={() => getOrders()}
            className={'cursor-pointer hover:text-red-200 transition-colors'}
            icon={'solar:refresh-bold'}
            sx={{ mr: 2 }}
          />
        </Stack>
        <div className="flex justify-end items-center pb-5">
          <Button onClick={() => navigate('/dashboard/promo/create')} variant="contained" className="bg-blue-400">
            <AiFillFileAdd className="mr-2" />
            Create Promocode
          </Button>
        </div>
        {!loading && (
          <Card>
            <Scrollbar>
              <TableContainer padding="10px" sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={promo?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {promo?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const {
                        code,
                        createdAt,
                        discount,
                        displayOnHome,
                        expiresAt,
                        isActive,
                        maxAmount,
                        title,
                        updatedAt,
                      } = row;
                      const selectedUser = selected.indexOf(name) !== -1;

                      const day = new Date(expiresAt);
                      const today = new Date();
                      const isExpired = day < today;

                      dayjs.extend(relativeTime);
                      const isAfter = dayjs(day).fromNow();
                      return (
                        <TableRow hover key={index} tabIndex={-1} className="pl-5" selected={selectedUser}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" paddingLeft={'5px'}>
                                <div className="text-gray-800">
                                  ({isExpired ? 'Expired' : `Expires ${moment(expiresAt).fromNow()}`})
                                </div>
                                {title}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant={(isActive && 'filled') || 'outlined'}
                              color={(isActive && 'success') || 'error'}
                            >
                              {(isActive && 'Active') || 'Inactive'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant={(displayOnHome && 'filled') || 'outlined'}
                              color={(displayOnHome && 'success') || 'error'}
                            >
                              {(displayOnHome && 'Displayed') || 'Not Displayed'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">{code}</TableCell>
                          <TableCell align="left">{discount}%</TableCell>
                          <TableCell align="left">NPR. {maxAmount}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={2}>
                              <IconButton
                                onClick={() => {
                                  navigate(`/dashboard/promo/${code}`);
                                }}
                              >
                                <Iconify icon={'ph:eye-light'} />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={promo.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        )}

        {loading && (
          <div>
            <h2> Loading ...</h2>
          </div>
        )}
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => openPop()}>
          <Iconify icon={'ph:eye-light'} sx={{ mr: 2 }} />
          View
        </MenuItem>

        <MenuItem>
          <Iconify icon={'subway:up-2'} sx={{ mr: 2, color: 'info.main' }} />
          {(stat === 'Processing' && 'Complete') || (stat === 'Pending' && 'Processing') || 'Complete'}
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem> */}
      </Popover>
    </div>
  );
}
