import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const users = JSON.parse(localStorage.getItem('users'));
  useEffect(() => {
    if (!!users?.token) {
      navigate('/dashboard/app');
    }
    setLoading(false);
  },[
    location.pathname
  ]);

  if (loading) {
    return (
      <div className="h-screen flex -300 justify-center items-center">
      <div className="py-4 rounded shadow-md w-60 sm:w-80 animate-pulse bg-gray-50">
	<div className="flex p-4 space-x-4 sm:px-8">
		<div className="flex-shrink-0 w-16 h-16 rounded-full bg-gray-300"></div>
		<div className="flex-1 py-2 space-y-4">
			<div className="w-full h-3 rounded bg-gray-300"></div>
			<div className="w-5/6 h-3 rounded bg-gray-300"></div>
		</div>
	</div>
	<div className="p-4 space-y-4 sm:px-8">
		<div className="w-full h-4 rounded bg-gray-300"></div>
		<div className="w-full h-4 rounded bg-gray-300"></div>
		<div className="w-3/4 h-4 rounded bg-gray-300"></div>
	</div>
</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title> Login | Casemandu </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Dashboard
            </Typography>

            <Typography variant="body2" gutterBottom sx={{ color: 'text.secondary' }}>
              If you don't have an account, Please contact your admin.
            </Typography>

            <Divider sx={{ my: 3 }}>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography> */}
            </Divider>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
