/* eslint-disable */
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SliderImage() {
  const url = process.env.REACT_APP_URL;
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [link, setLink] = useState(null);
  function fetchSliderImages() {
    fetch(`${url}/api/sliderImages`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        data = data.reverse();
        setImages(data);
      });
  }

  function getImage(e) {
    setImage(e.target.files[0]);
    // image url
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    setImageUrl(imgUrl);
  }

  function upload(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', image);

    fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const imageUrl = data.data.display_url;
        fetch(`${url}/api/sliderImages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            image: imageUrl,
            link: link,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            fetchSliderImages();
            setShowAdd(false);
            setImage(null);
            setImageUrl(null);
            addedNotify();
          });
      });
  }

  function deleteData(e) {
    fetch(`${url}/api/sliderImages/${e}`, {
      method: 'DELETE',
    })
      .then((res) => res.json())
      .then((data) => {
        fetchSliderImages();
        deleteNotify();
      });
  }

  const deleteNotify = () => toast('The selected Image deleted successfully!');
  const addedNotify = () => toast('Image added successfully!');

  useEffect(() => {
    fetchSliderImages();
  }, []);

  const [showAdd, setShowAdd] = useState(false);

  if (loading) return <h1>Loading...</h1>;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex pb-2 border-b-2 flex-row gap-3 justify-between">
        {/* generate a back button */}
        <div className="flex gap-3 items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            &lt;-
          </button>
          {/* generate a header with asd written */}
          <h1 className="text-xl font-bold">{images.length} Slider Images</h1>
        </div>
        <ToastContainer />
        {/* generate a button with add new image written */}
        <div
          onClick={(e) => setShowAdd(!showAdd)}
          className="flex gap-3 items-center bg-blue-100 p-2 rounded-xl hover:cursor-pointer hover:bg-blue-200 transition-all"
        >
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            {showAdd ? '-' : '+'}
          </button>
          <p className="">{showAdd ? 'Cancel' : 'Add New Image'}</p>
        </div>
      </div>

      {showAdd && (
        <div className="flex flex-col p-5 rounded bg-white shadow-lg transition-all">
          <h2 className="text-2xl font-semibold mb-4">Add New Image</h2>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="image" className="text-sm font-medium">
                Image:
              </label>
              <input
                type="file"
                onChange={(e) => getImage(e)}
                name="image"
                accept="image/*"
                id="image"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            {!!image && <img src={imageUrl} alt="" className="w-52 object-contain" />}

            <div className="flex flex-col gap-2">
              {/* eslint-disable-next-line */}
              <label htmlFor="link" className="text-sm font-medium">
                Link (where user goes after clicking the image):
              </label>
              <input
                type="text"
                onChange={(e) => setLink(e.target.value)}
                name="link"
                id="link"
                placeholder="Enter Link"
                className="border border-gray-300 p-2 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div className="flex gap-4 mt-4">
              <button
                onClick={upload}
                className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Add
              </button>
              <button
                onClick={(e) => setShowAdd(false)}
                className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full transition-all"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* generate a slider with images */}
      {(images?.length > 0 && (
        <div className="flex gap-5 flex-wrap">
          {images.map((image, index) => {
            return (
              <div className="flex flex-row" key={index}>
                <div className="flex gap-3 bg-white p-2 hover:scale-105 transition-all rounded flex-col">
                  <img className="h-52" src={image?.image} alt="" />
                  <p>Route: {image?.link}</p>
                  <div className="flex gap-2">
                    <button
                      onClick={() => deleteData(image._id)}
                      className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center py-2 px-4 rounded-full"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )) || <h1>No images found</h1>}
    </div>
  );
}

export default SliderImage;
