import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImConfused } from 'react-icons/im';

// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton, LocalizationProvider } from '@mui/lab';
// components
import Login from '../../../functions/auth/login';
import Iconify from '../../../components/iconify';
import { useSelector, useDispatch } from 'react-redux';
import { saveUsers } from 'src/reducer/usersReducer';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const users = JSON.parse(localStorage.getItem('users'));
  const [showPassword, setShowPassword] = useState(false);
  const [data, setData] = useState({ email: '', password: '' });

  const handleClick = async (e) => {
    e.preventDefault();
    const res = await Login(data.email, data.password);

    if (res.success === true) {
      dispatch(saveUsers(res.data));
      // save the user to localhost
      localStorage.setItem('users', JSON.stringify(res.data));
      window.location.href = '/dashboard/app';
      
    } else {
      console.log('error');
    }
  };

  const [showModel, setshowModel] = useState(false);

  return (
    <>
      <Stack spacing={3}>
        <TextField onChange={(e) => setData({ ...data, email: e.target.value })} name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setData({ ...data, password: e.target.value })}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover" onClick={() => setshowModel(true)}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        className="
            bg-blue-500
            hover:bg-blue-700
            text-white
            duration-200
            ease-in-out
            transform
  text-center            
          "
        type="submit"
        variant="contained"
        onClick={(e) => handleClick(e)}
      >
        Login
      </LoadingButton>

      {showModel && (
        <div className="fixed  fade flex bg-[#0000002a] backdrop-blur-md top-0 left-0 h-screen w-full z-50 justify-center items-center text-black">
          <div className="relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md shadow-md sm:py-8 sm:px-12 bg-[#000000e1] text-gray-100">
            <button
              className="absolute top-2 right-2 hover:text-red-800 duration-200"
              onClick={() => setshowModel(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                fill="currentColor"
                className="flex-shrink-0 w-6 h-6"
              >
                <polygon points="427.314 107.313 404.686 84.687 256 233.373 107.314 84.687 84.686 107.313 233.373 256 84.686 404.687 107.314 427.313 256 278.627 404.686 427.313 427.314 404.687 278.627 256 427.314 107.313" />
              </svg>
            </button>
            {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-40 h-40 fill-current shrink-0 dark:text-default-400"
          >
            <path d="M68.328,383.063a31.654,31.654,0,0,1,.207-32.118l50.883-86.406,11.516,50.76,31.207-7.08L138.257,202.944,32.983,226.828l7.08,31.207,53.149-12.058L40.96,334.707a64,64,0,0,0,55.149,96.476h82.435l32-32H96.109A31.655,31.655,0,0,1,68.328,383.063Z" />
            <path d="M283.379,79.762l53.747,91.268-49.053-7.653-4.934,31.617L389.8,211.635l16.64-106.66-31.617-4.933-8.873,56.87L310.954,63.524a64,64,0,0,0-110.3,0l-39.939,67.82,10.407,45.39,57.106-96.972a32,32,0,0,1,55.148,0Z" />
            <path d="M470.65,334.707l-47.867-81.283-41.148-6.812,61.441,104.333A32,32,0,0,1,415.5,399.183H304.046l38.359-38.358L319.778,338.2l-76.332,76.332,76.332,76.333,22.627-22.628-37.052-37.051H415.5a64,64,0,0,0,55.149-96.476Z" />
          </svg> */}
            <ImConfused size={40} />
            <h2 className="text-2xl font-semibold leading-tight tracking-wide">Forgot your password?</h2>
            <p className="flex-1 text-center dark:text-gray-400">Contact your admin to reset your password.</p>
            <button
              type="button"
              onClick={() => setshowModel(false)}
              className="px-8 py-3 font-semibold rounded-full bg-blue-800 hover:bg-blue-900 duration-300 dark:text-gray-900"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
