import { fetchPromoFailure, fetchPromoRequest, fetchPromoSuccess } from 'src/reducer/promoReducer';

async function fetchPromo(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchPromoRequest());
    const response = await fetch(url + '/api/promocodes/all', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch Promo Codes');
    }

    let promo = await response.json();
    promo = promo.reverse();
    dispatch(fetchPromoSuccess(promo));
    return promo;
  } catch (error) {
    console.error(error);
    dispatch(fetchPromoFailure(error));
    throw error;
  }
}

export default fetchPromo;
