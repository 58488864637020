import { fetchOrdersFailure, fetchOrdersRequest, fetchOrdersSuccess } from 'src/reducer/ordersReducer';

async function fetchOrders(dispatch, token) {
  const url = process.env.REACT_APP_URL; // Replace with your actual product URL

  try {
    dispatch(fetchOrdersRequest());

    const response = await fetch(url + '/api/orders', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch orders');
    }

    let orders = await response.json();
    dispatch(fetchOrdersSuccess(orders));
    const data = { success: true, orders };
    return data;
  } catch (error) {
    console.error(error);
    dispatch(fetchOrdersFailure(error));
    const data = { success: false, message: error.message };
    return data;
  }
}

export default fetchOrders;
