/* eslint-disable */
import { Card, Input, Checkbox, Textarea, Button, Typography, Option } from '@material-tailwind/react';
import { Select } from '@mui/material';
import { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function OfferAdd(close) {
  const url = process.env.REACT_APP_URL;
  const [categories, setCategories] = useState([]);
  const [image, setImage] = useState(null);
  const [imageLink, setImageLink] = useState(null);
  const [name, setName] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [category, setCategory] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [models, setModels] = useState([]);
  const [modelsName, setModelsName] = useState([]);
  const [brands, setBrands] = useState([]);

  function getCategory() {
    fetch(`${url}/api/categories`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
      });
  }

  function getModels() {
    fetch(`${url}/api/phones`)
      .then((res) => res.json())
      .then((data) => {
        setBrands(data);
      });
  }

  function setModel(e) {
    const models = brands.find((brand) => brand._id === e.target.value);

    setModels(models?.models);
  }

  useEffect(() => {
    getCategory();
    getModels();
  }, []);

  function pushModels(e, id) {
    setSelectedModels([...selectedModels, id]);
    // reset select
    e.target.value = '';
  }

  function popModel(id) {
    const newModels = selectedModels.filter((model) => model !== id);
    setSelectedModels(newModels);
  }

  function getImage(e) {
    setImage(e.target.files[0]);
    setImageLink(URL.createObjectURL(e.target.files[0]));
  }

  function upload() {
    const formData = new FormData();
    formData.append('image', image);

    fetch('https://api.imgbb.com/1/upload?key=' + process.env.REACT_APP_IMGBB, {
      method: 'POST',
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const imageUrl = data.data.display_url;

        fetch(`${url}/api/offers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            title: name,
            description: description,
            price: price,
            discount: discount,
            category: category,
            image: imageUrl,
            models: selectedModels,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setImage(null);
            setSelectedModels([]);
            setName(null);
            setDescription(null);
            setPrice(null);
            setCategory(null);
            setDiscount(null);
            setImageLink(null);
            addedNotify();
            window.location.reload();
          });
      });
  }

  const addedNotify = () => {
    toast.success('Offer added successfully', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <Card color="transparent" className="overflow-auto h-96" shadow={false}>
      <form className="mt-8 mb-2  w-100 max-w-screen-lg">
        <div className="mb-4 flex flex-col gap-6">
          <Input onChange={(e) => setName(e.target.value)} size="lg" label="Name" />
          <Textarea onChange={(e) => setDescription(e.target.value)} size="lg" label="Description" />
          <Input onChange={(e) => setPrice(e.target.value)} type="number" size="lg" label="Price" />
          <Input onChange={(e) => setDiscount(e.target.value)} size="lg" label="Discount (in %)" />

          {/* Image */}
          <Input onChange={(e) => getImage(e)} type="file" size="lg" label="Image" />
          {!!imageLink && <img className="h-40 object-contain" src={imageLink} alt="" />}

          <select
            size="lg"
            label="Category"
            onChange={(e) => setCategory(e.target.value)}
            className="
                    bg-white
                    border
                    border-gray-400
                    hover:border-gray-500
                    px-4
                    py-2
                    pr-8
                    rounded
                    shadow
                    leading-tight
                    focus:outline-none
                    focus:shadow-outline
                    appearance-none
                    
                    "
          >
            <option value="" disabled selected>
              Select Category
            </option>
            {categories.map((category) => {
              return <option value={category._id}>{category.title}</option>;
            })}
          </select>

          <select
            size="lg"
            label="Select Mobile Brand"
            onChange={(e) => setModel(e)}
            className="
                    bg-white
                    border
                    border-gray-400
                    hover:border-gray-500
                    px-4
                    py-2
                    pr-8
                    rounded
                    shadow
                    leading-tight
                    focus:outline-none
                    focus:shadow-outline
                    appearance-none
                    
                    "
          >
            <option value="" disabled selected>
              Select Brand
            </option>
            {brands?.map((brand) => {
              return <option value={brand?._id}>{brand?.name}</option>;
            })}
          </select>

          {!!models && (
            <select
              size="lg"
              label="Select Model"
              onChange={(e) => pushModels(e, e.target.value)}
              className="
                    bg-white
                    border
                    border-gray-400
                    hover:border-gray-500
                    px-4
                    py-2
                    pr-8
                    rounded
                    shadow
                    leading-tight
                    focus:outline-none
                    focus:shadow-outline
                    appearance-none
                    
                    "
            >
              <option value="" disabled selected>
                Select Model
              </option>
              {models?.map((brand) => {
                return <option value={brand?.name}>{brand?.name}</option>;
              })}
            </select>
          )}

          <div className="flex flex-wrap max-w-full gap-2">
            {selectedModels?.map((model) => {
              return (
                <div className="p-2  flex gap-2 items-center bg-gray-200 rounded" onClick={() => popModel(model)}>
                  {' '}
                  {model}{' '}
                  <p className="h-5 w-5 flex justify-center items-center cursor-pointer hover:bg-red-200 text-xs rounded-full bg-red-300  text-white">
                    X
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex gap-5 justify-center items-start">
          <Button className="mt-6" onClick={upload} fullWidth>
            Save
          </Button>
        </div>
        <ToastContainer />
      </form>
    </Card>
  );
}
