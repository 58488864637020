/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Customize() {
  const url = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  function fetchSliderData() {
    fetch(`${url}/api/phones/brands`)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        data = data.reverse();
        setData(data);
      });
  }

  const addedNotify = () => toast('Image added successfully!');

  useEffect(() => {
    fetchSliderData();
  }, []);

  const [showAdd, setShowAdd] = useState(false);

  function navto(e) {
    navigate(`/dashboard/customize/${e}`);
  }

  if (loading) return <h1>Loading...</h1>;

  return (
    <div className="flex flex-col gap-2 p-5">
      <div className="flex pb-2 border-b-2 flex-row gap-3 justify-between">
        {/* generate a back button */}
        <div className="flex gap-3 items-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            &lt;-
          </button>
          {/* generate a header with asd written */}
          <h1 className="text-xl font-bold">Select a Brand ({Data.length})</h1>
        </div>
        <ToastContainer />
        {/* generate a button with add new image written */}
        <div
          onClick={(e) => navigate('/dashboard/brands')}
          className="flex gap-3 items-center bg-blue-100 p-2 rounded-xl hover:cursor-pointer hover:bg-blue-200 transition-all"
        >
          <button className="bg-blue-500 hover:bg-blue-700 text-white h-8 w-8 flex justify-center items-center font-bold py-2 px-4 rounded-full">
            {showAdd ? '-' : '+'}
          </button>
          <p className="hidden md:flex">{showAdd ? 'Cancel' : 'Add New Brand'}</p>
        </div>
      </div>

      {/* generate a slider with images */}
      {(Data?.length > 0 && (
        <div className="flex gap-5 flex-wrap">
          {Data.map((brand, index) => {
            return (
              <div className="flex flex-row" key={index}>
                <div
                  onClick={() => navto(brand._id)}
                  className="flex gap-3 bg-gray-300 p-2 hover:scale-105 cursor-pointer transition-all rounded flex-col"
                >
                  <img
                    src={brand.thumbnail}
                    alt={brand.name}
                    className="h-32 w-32 object-contain aspect-square  rounded"
                  />
                  <p className="text-black text-center">{brand.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      )) || <h1>No images found</h1>}
    </div>
  );
}

export default Customize;
